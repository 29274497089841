<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../../assets/trade.png" alt />
        <span class="center">财务功能</span>
        <img src="../../../assets/right.png" alt />
        <span class="center">提现管理</span>
      </div>
      <div style="display: flex; align-items: center">
        <span class="seach_text">关键字：</span>

        <el-input
          placeholder="请输入关键字"
          v-model="formInline.keywords"
          @input="loadData('seath')"
          size="mini"
          style="margin-right: 10px; width: 300px"
          prefix-icon="el-icon-search"
          clearable
        ></el-input>
        <!-- <el-button
          v-access
          data-ctrl="withdrawalAdd"
          @click="addObject"
          size="small"
          type="primary"
          icon="el-icon-plus"
          >新建</el-button
        >-->
      </div>
    </div>

    <el-table :data="list" style="width: 100%" row-key="id" :tree-props="{ children: 'retrys' }">
      <!-- <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.retrys" style="width: 100%">
            <el-table-column label="状态" :formatter="retryStatusFormater" prop="status"></el-table-column>
            <el-table-column label="描述" prop="remark"></el-table-column>
            <el-table-column label="重试时间">
              <template slot-scope="scope">{{ scope.row.create_time | dateVal }}</template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>-->

      <el-table-column
        label="交易编号"
        show-overflow-tooltip
        style="display: flex;
    align-items: center;"
      >
        <template slot-scope="scope">
          <p>{{ scope.row.code }}</p>
        </template>
      </el-table-column>
      <el-table-column label="支付方信息" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="scope.row.payAccountName">
            <p>{{ scope.row.payAccountName }}</p>
            <p>{{ scope.row.payAccount }}</p>
          </div>
          <div v-else>
            <p>{{ payAccountName }}</p>
            <p>{{ payAccount }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="所属项目">
        <template slot-scope="scope">
          <span style="color: blue" v-if="scope.row.project">{{ scope.row.project.name }}</span>
        </template>
      </el-table-column>-->
      <el-table-column label="所属工资包" width="200">
        <template slot-scope="scope">
          <span
            v-if="scope.row.task"
            style="color: blue"
            @click="goDetail({ code: scope.row.task.code })"
          >{{ scope.row.task.name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="收款方" width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>
            <p>{{ scope.row.payeeAccount }}</p>
            <p>{{ scope.row.payeeAccountName }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="收款方银行" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.payeeBankName }}</div>
        </template>
      </el-table-column>

      <el-table-column label="金额/元">
        <template slot-scope="scope">{{ scope.row.money | money }}</template>
      </el-table-column>
      <el-table-column label="支付时间" width="150">
        <template slot-scope="scope">
          {{
            scope.row.create_time | dateVal
          }}
        </template>
      </el-table-column>

      <el-table-column label="交易状态">
        <template slot-scope="scope">
          {{
            scope.row.status | payStateVal
          }}
        </template>
      </el-table-column>

      <el-table-column label="备注" show-overflow-tooltip>
        <template slot-scope="scope">
          {{
            scope.row.remark
          }}
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            v-if="!scope.row.parentCode && scope.row.status === '2'"
            @click="excute(scope.row)"
          >重试</el-button>
          <!-- <el-button
            type="text"
            @click="viewPdf(scope.row)"
            
            >查看回单</el-button
          >-->
        </template>
      </el-table-column>

      <!-- <el-table-column
        label="自动提现的卡参数{bank_name,bank_code,bank_branch,bank_line_no}"
      >
        <template slot-scope="scope">
          {{ scope.row.withdrawal_params }}
        </template>
      </el-table-column>-->

      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popconfirm
            @confirm="del({ code: scope.row.code })"
            confirmButtonText="确定"
            cancelButtonText="取消"
            icon="el-icon-info"
            iconColor="red"
            title="确定删除此这条数据？"
          >
            <el-button slot="reference" style="margin-left:10px" type="text"
              >删除</el-button
            >
          </el-popconfirm>
          |
          <span
            class="clickColor"
            @click="
              editDialogVisible = true;
              focusCode = scope.row.code;
            "
            >编辑</span
          >
        </template>
      </el-table-column>-->
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog
      title="重新支付工资"
      :visible.sync="dialogVisible"
      width="900px"
      :before-close="handleClose"
    >
      <el-descriptions title="用户信息">
        <el-descriptions-item label="付款账户">{{ payAccountName }}-{{ payAccount }}</el-descriptions-item>
        <el-descriptions-item label="工资包">
          <span v-if="newList.task">
            {{
              newList.task.name
            }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="收款人">
          {{
            newList.payeeAccountName
          }}
        </el-descriptions-item>
        <el-descriptions-item label="收款账户">
          {{
            newList.payeeAccount
          }}
        </el-descriptions-item>
        <el-descriptions-item label="交易金额">
          {{
            newList.money | money
          }}
        </el-descriptions-item>
        <el-descriptions-item label="汇款状态">
          {{
            newList.status | payStateVal
          }}
        </el-descriptions-item>
        <el-descriptions-item label="汇款状态描述">
          {{
            newList.state_reason
          }}
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="retry(newList.code)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
//接口：
import { api } from '/src/api/base';
// import { withdrawalListResultMap } from '/src/lib/global';

//删除接口
export const TradeDel = api('/trade')('trade.del.json');
export const widthDrawRetry = api('')('widthDraw.retry.json');
//查询列表接口
export const WithdrawalList = api('')('widthDraw.list.json');

export const enterpriseAccountInfo = api()('enterprise.account.info.json');

export default {
  components: {
    // addDialog: () => import('./Add'),
  },
  data () {
    return {
      focusCode: '',
      pageSize: 10,
      newList: {},
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: '',
      },
      list: [],
      dialogVisible: false,
      editDialogVisible: false,
      dialogData: null,
      payAccount: '',
      payAccountName: '',
    };
  },
  created () {
    this.loadData();
    this.getEnterpriseInfo();
  },
  methods: {
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleClose () {
      this.dialogVisible = false;
    },
    async getEnterpriseInfo () {
      await enterpriseAccountInfo({
        code: window.localStorage.getItem('enterprise'),
      }).then((res) => {

        this.payAccount = res.accountCode;
        this.payAccountName = res.accountName;
      });
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData (seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        $ignoreRepeat: true,
      };
      WithdrawalList(params).then((res) => {
        this.list = res.list;


        this.total = res.total;
      });
    },


    addObject () {
      this.addDialogVisible = true;

    },
    goEdit (obj) {
      this.focusCode = obj.code;
      this.editDialogVisible = true;
    },
    goDetail (obj) {

      this.$router.push({
        path: '/system/task/detail',
        query: obj,

      });
    },
    del (obj) {
      TradeDel(obj).then((res) => {
        console.log(res);
        this.$message.success('内容成功删除！');
        this.loadData();
      });
    },
    close () {
      this.loadData();
    },
    putAway () {
      this.formInline.keywords = '';
      this.pageNumber = 1;
      this.loadData();
    },
    async retry (code) {

      await widthDrawRetry({ code: code });
      this.dialogVisible = false;
      this.$message.success('提现成功');
      this.loadData();
    },
    async excute (row) {

      this.newList = row;

      this.dialogVisible = true;

    },
    viewPdf (row) {
      const nowDay = moment([
        moment().year(),
        moment().month(),
        moment().date(),
      ]);
      const createDay = moment([
        moment(row.create_time).year(),
        moment(row.create_time).month(),
        moment(row.create_time).date(),
      ]);
      const diffDay = nowDay.diff(createDay, 'days');

      if (diffDay < 1 && moment(row.create_time).hour() < 11)
        return this.$message.error(
          '不能查询当天回单，请于明天11点之后再进行查询！'
        );
      window.open(
        `https://file.lgbfss.com/pingan/receipt/${row.filePath}`,
        '_blank'
      );
    },
    retryStatusFormater (row) {
      switch (row.status) {
        case '1':
          return '成功'
        case '2':
          return '失败'
        default:
          return '支付中'
      }
    }
  },
};
</script>
<style >
.el-table .cell.el-tooltip {
  display: flex;
  align-items: center;
}
.el-table [class*="el-table__row--level"] .el-table__expand-icon {
  font-size: 20px;
}
.el-table__row el-table__row--level-1 {
  background: red;
}
</style>
<style lang="scss" scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
